.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.collapsible {
    transition: opacity 0.25s ease-in;
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.collapsible.active {
    opacity: 1;
    height: auto;
}

@media print {
    .noPrint {
        display: none;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(53, 53, 53, 0.75);
}

.pds-modal .pds-modal-dialog {
    z-index: 9998
}

.pds-modal-dialog-overlay {
    z-index: 9997
}

@media only screen and (min-width: 0px) {
    .disclosure-scroll {
        min-height: 20px;
        max-height: 250px;
        overflow-y: auto;
        padding: 19px;
        margin-bottom: 20px;
        background-color: #f1f1f1;
        border-width: 1px;
        border-style: solid;
        border-color: #c7c7c7;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
}